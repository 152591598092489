import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import InfoIcon from "../../Assets/NewByrneIcons/IT request.svg";
import InfoIconHover from "../../Assets/NewByrneIcons/IT request - Hover.svg";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import bidash from "../../Assets/NewByrneIcons/Dashboard BI.svg";
import bidashHover from "../../Assets/NewByrneIcons/Dashboard BI - hover.svg";
import chatBot from "../../Assets/NewByrneIcons/Chat Bot.svg";
import chatBotHover from "../../Assets/NewByrneIcons/Chat Bot - hover.svg";
import closeIcon from "../../Assets/Images/panel - close.svg";
import openIcon from "../../Assets/Images/Panel open.svg";
import byrneHrms from "../../Assets/departmentContentEditor/Byrne HRMS.svg";
import byrneHrmsHover from "../../Assets/NewByrneIcons/Byrne HRMS - Hover.svg";
import byrneERP from "../../Assets/departmentContentEditor/byrne  - department.svg";
import byrneERPHover from "../../Assets/NewByrneIcons/byrne - department - hover.svg";
import PhoneIcon from "@mui/icons-material/Phone";
import byrneCRM from "../../Assets/departmentContentEditor/crm.svg";
import byrneCRMHover from "../../Assets/NewByrneIcons/crm - hover.svg";
import SpCRM from "../../Assets/departmentContentEditor/sp - department - hover-1.svg";
import SpCRMHover from "../../Assets/NewByrneIcons/sp - department.svg";
import knowIcon from "../../Assets/NewByrneIcons/Knowledge Center.svg";
import knowIconHover from "../../Assets/NewByrneIcons/Knowledge Center - hover.svg";
import employeeIcon from "../../Assets/NewByrneIcons/phone.svg";
import galleryIconHover from "../../Assets/NewByrneIcons/Gallery.svg";
import galleryIcon from "../../Assets/NewByrneIcons/Gallery - Active.svg";
import employeeIconHover from "../../Assets/NewByrneIcons/phone  - hover.svg";
import Gallery from "./../../Assets/byrne - svg/gallery - white.svg";
import GalleryH from "./../../Assets/byrne - svg/gallery.svg";
import videoIconHover from "../../Assets/NewByrneIcons/Video-icon-hover.svg";
import videoIcon from "../../Assets/NewByrneIcons/Video-icon.svg";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./SidebarMenu.css";
import { link } from "fs";

const products = [
  {
    icon: employeeIcon,
    hoverIcon: employeeIconHover,
    label: "Employee Directory",
    link: "/employeeDirectory",
  },

  {
    icon: byrneHrms,
    hoverIcon: byrneHrmsHover,
    label: "Byrne HRMS",
    link: "http://hrms.byrneintranet.com:4443/",
  },
   {
    icon: videoIcon,
    hoverIcon: videoIconHover,
    label: "Byrne 3D Videos",
    link: "https://authn.matterport.com/login?target=https%3A%2F%2Fmy.matterport.com%2Ffolders%2F7nESTMNjw1b/",
  },
  {
    icon: byrneERP,
    hoverIcon: byrneERPHover,
    label: "Byrne ERP",
    link: "https://d365prod.byrneintranet.com/namespaces/AXSF/",
  },
  {
    icon: byrneCRM,
    hoverIcon: byrneCRMHover,
    label: "Byrne CRM",
    // link: "https://d365prod.byrneintranet.com/namespaces/AXSF/",
    link: "https://bercrm.byrneintranet.com",
  },

  {
    icon: SpCRM,
    hoverIcon: SpCRMHover,
    label: "Spacemaker ERP",
    link: "https://ax.spacemaker.ae/namespaces/AXSF/",
  },
  {
    icon: byrneCRM,
    hoverIcon: byrneCRMHover,
    label: "Spacemaker CRM",
    // link: "https://ax.spacemaker.ae/namespaces/AXSF/",
    link: "https://smcrm.byrneintranet.com",
  },

  {
    icon: InfoIcon,
    hoverIcon: InfoIconHover,
    label: "IT Requests",
    link: "/itRequests",
  },
  {
    icon: Gallery,
    hoverIcon: GalleryH,
    label: "Byrne Photo Gallery",
    link: "/CorporateGallery/65fbdb0cf232ab18149de9af",
  },

  {
    icon: galleryIcon,
    hoverIcon: galleryIconHover,
    label: "Thirdlight Photo Library",
    link: "https://byrnerental.chorus-mk.thirdlight.com/",
  },
  {
    icon: bidash,
    hoverIcon: bidashHover,
    label: "BI Dashboard",
    link: "https://bi.byrneintranet.com/",
  },
 
  // {
  //   icon: knowIcon,
  //   hoverIcon: knowIconHover,
  //   label: "Knowledge Center",
  //   link: "https://byrneintranet.com/Operations/common-1631510128?sid=knowledge-center-1631510093#knowledge-center-1631510093",
  // },

  // {
  //   icon: chatBot,
  //   hoverIcon: chatBotHover,
  //   label: "Byrne AI Bot",
  //   link: "https://byrneintranet.com/Operations/common-1631510128?sid=knowledge-center-1631510093#knowledge-center-1631510093",
  // },
];
const SidebarMenu = () => {
  return (
    <div>
      <div className="mainMenuContainer">
        {/* {showQuickLinks && ( */}
        <div>
          <Typography className="useFull-new-text">
            USEFUL LINKS
            {/* <span
                      onClick={toggleQuickLinks}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={closeIcon}
                        alt="Close"
                        style={{
                          float: "right",
                          padding: "4px",
                          borderRadius: "50%",
                          fontWeight: "bold",
                        }}
                      />
                    </span> */}
          </Typography>
          {/* <Box>
            {products.map((product, index) => (
              <div key={index}>
                <Link to={product.link} className="useFull-div">
                  <div className="icons-new-use">{product.icon}</div>
                  <span className="label-new-link">{product.label}</span>
                </Link>
              </div>
            ))}
          </Box> */}
          <Box>
            {products.map((product, index) => (
              <div key={index}>
                {product.label === "Employee Directory" ||
                product.label === "IT Requests" ||
                product.label === "Byrne Photo Gallery" ? (
                  <Link to={product.link} className="useFulldiv">
                    <div className="icons-new-use">
                      <img
                        src={product.icon}
                        alt={`${product.label} icon`}
                        style={{ width: "18px" }}
                      />
                      <img
                        src={product.hoverIcon}
                        alt={`${product.label} hover icon`}
                        style={{ width: "18px" }}
                      />
                    </div>
                    <span className="label-new-link">{product.label}</span>
                  </Link>
                ) : (
                  <a target="_blank" href={product.link} className="useFulldiv">
                    <div className="icons-new-use">
                      <img
                        src={product.icon}
                        alt={`${product.label} icon`}
                        style={{ width: "18px" }}
                      />
                      <img
                        src={product.hoverIcon}
                        alt={`${product.label} hover icon`}
                        style={{ width: "18px" }}
                      />
                    </div>
                    <span className="label-new-link">{product.label}</span>
                  </a>
                )}
              </div>
            ))}
          </Box>
        </div>
        {/* )} */}
        {/* {!showQuickLinks && (
                  <div>
                    <div>
                      <IconButton onClick={toggleQuickLinks}>
                        <img
                          src={openIcon}
                          alt="Open"
                          style={{ color: "#ffffff" }}
                        />
                      </IconButton>
                    </div>
                    <Box style={{ height: "auto" }}>
                      {products.map((product, index) => (
                        <Link
                          to={product.link}
                          key={index}
                          className="useFull-link"
                        >
                          <Tooltip
                            title={product.label}
                            placement="right-start"
                          >
                            <div className="usesIcon">{product.icon}</div>
                          </Tooltip>
                        </Link>
                      ))}
                    </Box>
                  </div>
                )} */}
      </div>
    </div>
  );
};

export default SidebarMenu;
